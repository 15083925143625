import axios from 'axios';

export const api = {
  login: async (credentials: Record<string, string>): Promise<string> => {
    try {
      const {
        data: { token },
      } = await axios.post(
        `${process.env.REACT_APP_BACKEND_ID_URL}Verification/login`,
        credentials,
      );

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_ID_URL}iframe/session`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      return data;
    } catch (err) {
      throw new Error(err as string);
    }
  },
};
