import React, { FC, useState } from 'react';
import { api } from './api';
import { Modal, Button, Form, Input, Spin } from 'antd';

interface Props {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
  setToken: (token: string) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

export const LoginModal: FC<Props> = ({
  isModalVisible,
  setIsModalVisible,
  setToken,
  setIsLoggedIn,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async (values: Record<string, string>) => {
    try {
      const url = await api.login(values);

      // eslint-disable-next-line
      const [_, token] = url.split('token=');

      setToken(token);
      setIsLoggedIn(true);
    } catch (err) {
      throw err;
    } finally {
      setIsModalVisible(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      title={isLoading ? '' : 'Login'}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ height: '240px' }}
    >
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              setLoading(true);
            }}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      {isLoading && (
        <Spin
          style={{
            width: '100%',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#1f1f1f',
            top: 0,
            left: 0,
            zIndex: 99,
            height: '240px',
          }}
        />
      )}
    </Modal>
  );
};
