import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import { Button } from 'antd';
import { LoginModal } from './Login';

function App() {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isInitialButton, setInitialButton] = useState<boolean>(true);

  const [token, setToken] = useState<string>('');

  const loginHandler = useCallback(() => {
    setIsModalVisible(true);
    setInitialButton(false);
  }, []);

  const signupHandler = useCallback(() => {
    setIsModalVisible(true);
    setInitialButton(false);
  }, []);

  const logoutHandler = useCallback(() => {
    setIsLoggedIn(false);
    setToken('logout');
    setInitialButton(false);
  }, []);

  const authorizeHandler = useCallback(() => {
    setIsLoggedIn(true);
    setToken('authorized');
    setInitialButton(false);
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (!iframe) return;

    iframe.onload = () => {
      if (iframe.contentWindow) {
        iframe.contentWindow.postMessage({}, '*');
      }
    };

    const handleMessage = (event: MessageEvent) => {
      if (event.data) {
        switch (event.data.type) {
          case 'LOGIN':
            loginHandler();
            break;
          case 'SIGNUP':
            signupHandler();
            break;
          case 'LOGOUT':
            logoutHandler();
            break;
          case 'AUTHORIZE':
            authorizeHandler();
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [loginHandler, signupHandler, logoutHandler, authorizeHandler]);

  useEffect(() => {
    if (!iframeRef.current || !iframeRef.current.contentWindow) return;

    iframeRef.current.contentWindow.postMessage({ token }, '*');
  }, [token]);

  return (
    <div className="wrapper">
      <header className="header">
        <div className="truncate">PARENT HEADER</div>
        {isInitialButton ? (
          <Button
            style={{ width: '150px', height: '40px' }}
            type="primary"
            disabled
            loading
          />
        ) : isLoggedIn ? (
          <Button
            type="primary"
            onClick={logoutHandler}
            size="large"
            danger
            style={{ width: '150px' }}
          >
            LOGOUT
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={loginHandler}
            size="large"
            style={{ width: '150px' }}
          >
            LOGIN / SIGNUP
          </Button>
        )}
      </header>
      <iframe
        title="sport"
        ref={iframeRef}
        className="iframe"
        src={process.env.REACT_APP_IFRAME_URL}
      />
      <LoginModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        setToken={setToken}
        setIsLoggedIn={setIsLoggedIn}
      />
    </div>
  );
}

export default App;
